// Code based on bootstrap embed utility
// https://getbootstrap.com/docs/4.0/utilities/embed/

.embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: '';
  }

  &--1by1 {
    &::before {
      padding-top: 100%;
    }
  }

  &--4by3 {
    &::before {
      padding-top: 75%;
    }
  }

  &--16by9 {
    &::before {
      padding-top: 56.25%;
    }
  }

  &--21by9 {
    &::before {
      padding-top: 42.857143%;
    }
  }

  &__item,
  iframe,
  object,
  embed,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
