.b-label {
  display: block;
  color: currentColor;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0.5em;

  &__asterisk {
    color: flexy-color(error);
    font-weight: bold;
  }

  &--checkbox {
    color: flexy-color(text);
  }
}
