.image-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  &__image {
    @include hide-text;
    display: block;
    background: {
      color: rgba(#000, 0.05);
      size: cover;
      position: 50% 50%;
      repeat: no-repeat;
    }
    height: 120px;
    border-radius: $flexy-theme-border-radius;
  }

  &__item {
    position: relative;
    border-radius: $flexy-theme-border-radius;
    background: {
      color: rgba(flexy-color(placeholder), .2);
    }
    transition: all .2s ease;

    &:hover {
      box-shadow: 0 16px 48px rgba(#000, .1), 0 0 0 1px rgba(#000, .05);

      .image-list__remove {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }
    }
  }

  &__remove {
    transition: all .2s ease;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    z-index: 2;
    width: 2rem;
    height: 2rem;
    background: {
      color: flexy-color();
    }
    color: #fff;
    font-size: 2rem;
    line-height: 2rem;
    border-radius: 100%;
    box-shadow: 0 16px 48px rgba(#000, .1), 0 0 0 1px rgba(#000, .05);
    transform: scale(.6);
    cursor: pointer;
  }
}
