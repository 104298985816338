$flexy-theme-breadcrumb-link-color: flexy-color() !default; // Цвет ссылки
$flexy-theme-breadcrumb-color: flexy-color(placeholder) !default; // Цвет текста

.b-breadcrumb {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  &__more {
    background: {
      color: #f1f1f1;
    }
    display: flex;
    border-radius: .275em;
    cursor: pointer;
    align-items: center;
    width: auto;
    padding: 0;
    color: $flexy-theme-breadcrumb-color;
  }

  &__item {
    display: flex;
    align-items: center;
    width: auto;
    padding: 0;
    color: $flexy-theme-breadcrumb-color;
  }

  &__icon {
    height: 1em;
  }

  &__icon + &__text {
    margin-left: .2rem;
  }

  &__symbol {
    display: flex;
    align-items: center;
    padding: 0 0.5em;
    color: $flexy-theme-breadcrumb-color;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__text {
    color: $flexy-theme-breadcrumb-color;
  }

  &__link {
    color: $flexy-theme-breadcrumb-color;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:hover {
      color: darken($flexy-theme-breadcrumb-link-color, 10%);
    }

    &--inactive {
      cursor: default;

      &:hover {
        color: $flexy-theme-breadcrumb-color;
      }
    }
  }
}
