$flexy-spinner-track-color: rgba(flexy-color(), .1) !default;
$flexy-spinner-color: flexy-color() !default;

.b-spinner {
  // allow paths to overflow container -- critical for edges of circles!
  overflow: visible;
  vertical-align: middle;

  path {
    fill-opacity: 0;
  }

  &__head {
    transform-origin: center;
    transition: stroke-dashoffset (100ms * 2) cubic-bezier(0.4, 1, 0.75, 0.9);
    animation: spinner-animation (100ms * 5) linear infinite;
    stroke: $flexy-spinner-color;
    stroke-linecap: round;

    &--white {
      stroke: #fff;
    }
  }

  &__track {
    stroke: $flexy-spinner-track-color;

    &--white {
      stroke: rgba(#fff, .5);
    }
  }

  &__global {
    padding: .8rem;
    border-radius: .3rem;
    background: {
      color: rgba(#000, .8);
    }
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 10;
  }

  &--overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

@keyframes spinner-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
