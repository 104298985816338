$tooltip-background-color: $dark-gray5 !default;
$tooltip-text-color: $light-gray5 !default;

$dark-tooltip-background-color: $light-gray3 !default;
$dark-tooltip-text-color: $dark-gray5 !default;

$tooltip-padding-vertical: $pt-grid-size !default;
$tooltip-padding-horizontal: 1.2 * $pt-grid-size !default;

.#{$ns}-tooltip {
  @include popover-sizing(
    $arrow-square-size: 22px,
    $arrow-offset: 3px,
    $arrow-target-offset: -4px
  );
  @include popover-appearance(
      $tooltip-background-color,
      $tooltip-text-color,
      $pt-tooltip-box-shadow,
      $pt-drop-shadow-opacity,
      $pt-border-shadow-opacity
  );
  // lightweight scale for tooltips
  @include react-transition(
      "#{$ns}-popover",
      (transform: scale(0.8) scale(1)),
    $duration: $pt-transition-duration,
    $after: "> &"
  );

  .#{$ns}-popover-content {
    padding: $tooltip-padding-vertical $tooltip-padding-horizontal;
  }

  @each $intent, $color in $pt-intent-colors {
    &.#{$ns}-intent-#{$intent} {
      .#{$ns}-popover-content {
        background: $color;
        color: $white;
      }

      .#{$ns}-popover-arrow-fill {
        fill: $color;
      }
    }
  }
}

.#{$ns}-tooltip-indicator {
  border-bottom: dotted 1px;
  cursor: help;
}
