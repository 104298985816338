.empty {
  &__icon {
    text-align: center;

    &,
    svg {
      height: 64px;
      width: auto;
      stroke: #6a6a6a;
    }
  }

  &__title {
    color: #6a6a6a;
    font-size: flexy-size(xlarge);
    text-align: center;
  }

  &__text {
    text-align: center;
    color: #6a6a6a;
  }
}
