.wrapper {
  display: flex;
  flex-direction: row;
  background: {
    color: #fff;
  }
  min-height: 100vh;
  max-width: $flexy-grid-wrapper-max-width;
  margin: 0 auto;
  padding: 0 $flexy-grid-gutter * 2 $flexy-grid-gutter * 2;

  &__menu {
    flex: 0;
    padding-top: 15px;
    & .padding {
      padding-right: 30px;
    }
  }

  &__container {
    flex: 1;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 15px;
  }

  @media (max-width: 1280px) {
    max-width: 100%;
  }
}
