// Namespace appended to the beginning of each CSS class: `.#{$ns}-button`.
$ns: "b" !default;

// easily the most important variable, so it comes up top
// (so other variables can use it to define themselves)
$pt-grid-size: 10px !default;

// see https://bitsofco.de/the-new-system-font-stack/
$pt-font-family: -apple-system,
"BlinkMacSystemFont",
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Open Sans",
"Helvetica Neue",
"Icons16", // support inline Palantir icons
sans-serif !default;

$pt-font-family-monospace: monospace !default;

$pt-font-size: $pt-grid-size * 1.4 !default;
$pt-font-size-large: $pt-grid-size * 1.6 !default;
$pt-font-size-small: $pt-grid-size * 1.2 !default;

// a little bit extra to ensure the height comes out to just over 18px (and browser rounds to 18px)
$pt-line-height: ($pt-grid-size * 1.8) / $pt-font-size + 0.0001 !default;

// Icon variables

$icons16-family: "Icons16" !default;
$icons20-family: "Icons20" !default;

$pt-icon-size-standard: 16px !default;
$pt-icon-size-large: 20px !default;

// Grids & dimensions

$pt-border-radius: floor($pt-grid-size / 3) !default;

// Buttons
$pt-button-height: $pt-grid-size * 3 !default;
$pt-button-height-small: $pt-grid-size * 2.4 !default;
$pt-button-height-large: $pt-grid-size * 4 !default;

// Inputs
$pt-input-height: $pt-grid-size * 3 !default;
$pt-input-height-large: $pt-grid-size * 4 !default;

// Others
$pt-navbar-height: $pt-grid-size * 5 !default;

// Z-indices
$pt-z-index-base: 0 !default;
$pt-z-index-content: $pt-z-index-base + 10 !default;
$pt-z-index-overlay: $pt-z-index-content + 10 !default;

// Transitions
$pt-transition-ease: cubic-bezier(0.4, 1, 0.75, 0.9) !default;
$pt-transition-ease-bounce: cubic-bezier(0.54, 1.12, 0.38, 1.11) !default;
$pt-transition-duration: 100ms !default;

// Light theme styles

$pt-input-box-shadow: inset border-shadow(0.15),
inset 0 1px 1px rgba($black, $pt-drop-shadow-opacity) !default;

$pt-dialog-box-shadow: $pt-elevation-shadow-4 !default;
$pt-popover-box-shadow: $pt-elevation-shadow-3 !default;
$pt-tooltip-box-shadow: $pt-popover-box-shadow !default;

// Dark theme styles

$pt-dark-input-box-shadow: inset border-shadow(0.3),
inset 0 1px 1px rgba($black, $pt-dark-drop-shadow-opacity) !default;

$pt-dark-dialog-box-shadow: $pt-dark-elevation-shadow-4 !default;
$pt-dark-popover-box-shadow: $pt-dark-elevation-shadow-3 !default;
$pt-dark-tooltip-box-shadow: $pt-dark-popover-box-shadow !default;
