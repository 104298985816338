.menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  margin-top: 15.0px;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;  
  }

  &__link {
    text-decoration: none;
    color: flexy-color(text);
    cursor: pointer;
    transition: all .2s ease;
    border-radius: 2px;
    padding: .3rem .5rem;

    &:hover {
      background: {
        color: rgba(227, 233, 237, .8);
      }
      color: flexy-color(text);
    }
  }

  &__item {
    $icon-size: 24px;

    display: grid;
    width: 100%;
    text-align: left;
    text-decoration: none;
    color: flexy-color(text);
    cursor: pointer;
    border-radius: 2px;
    padding: .3rem .5rem;
    grid-template-columns: 1fr;

    &--icon-left {
      grid-template-columns: $icon-size 1fr;
    }

    &--icon-right {
      grid-template-columns: 1fr $icon-size;
    }

    &--icon-left-right {
      grid-template-columns: $icon-size 1fr $icon-size;
    }

    &--icon-left,
    &--icon-left-right {
      .menu__text {
        margin-left: .5rem;
      }
    }

    &:hover {
      background: {
        color: flexy-color();
      }
      color: #fff;
    }
  }
}
