.dropzone {
  border: 2px dashed $flexy-block-input-color;
  border-radius: $flexy-theme-border-radius;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    border-color: $flexy-block-input-hover-color;
  }
}
